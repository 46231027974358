// ** React Imports
import { useState, ChangeEvent } from 'react'

// ** Next Import
import { useRouter } from 'next/router'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import ListItem from '@mui/material/ListItem'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import ListItemButton from '@mui/material/ListItemButton'
import InputAdornment from '@mui/material/InputAdornment'
import MuiAutocomplete, {
  AutocompleteRenderInputParams
} from '@mui/material/Autocomplete'
import { Button, Card, Stack } from '@mui/material'

// ** Icon Imports
import Icon from 'src/@core/components/icon'
import { useMutation, useQuery } from 'react-query'
import { fetchSample } from '@/@core/services/app.service'
import Box from '@mui/material/Box'
import { isWindow } from '@/@core/utils/utils'
import { useTranslation } from 'react-i18next'
import { Search } from '@mui/icons-material'

// Styled Autocomplete component
const Autocomplete = styled(MuiAutocomplete)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    paddingLeft: theme.spacing(3.5),
    backgroundColor: theme.palette.background.paper
  },
  [theme.breakpoints.up('md')]: {
    width: '55%'
  },
  [theme.breakpoints.up('xl')]: {
    width: '45%'
  },
  [theme.breakpoints.down('md')]: {
    width: '75%'
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}))

const links = [
  {
    name: 'Clinvar',
    url: 'https://www.ncbi.nlm.nih.gov/clinvar/',
    icon: '/images/links/clinvar.png'
  },

  // {
  //   name: 'Franklin',
  //   url: 'https://franklin.genoox.com/clinical-db/home',
  //   icon: '/images/links/franklin.png'
  // },
  // {
  //   name: 'Varsome',
  //   url: 'https://varsome.com/',
  //   icon: '/images/links/varsome.png'
  // },
  {
    name: 'Clingen',
    url: 'https://clinicalgenome.org/',
    icon: '/images/links/clingen.png'
  },
  {
    name: 'OMIM',
    url: 'https://www.omim.org/',
    icon: '/images/links/th.png'
  },
  {
    name: 'Orphanet',
    url: 'https://www.orpha.net/consor/cgi-bin/Disease_Search.php?lng=EN&data_id=20070&Disease_Disease_Search_diseaseGroup=FLG&Disease_Disease_Search_diseaseType=Gen&Disease(s)/group%20of%20diseases=Peeling-skin-syndrome-type-A&title=Peeling%20skin%20syndrome%20type%20A&search=Disease_Search_Simple',
    icon: '/images/links/orphanet.png'
  },
  {
    name: 'Decipher',
    url: 'https://www.deciphergenomics.org/',
    icon: '/images/links/decipher.png'
  },
  {
    name: 'HGMD',
    url: 'https://www.hgmd.cf.ac.uk/ac/index.php',
    icon: '/images/links/HGMD.png'
  },
  {
    name: 'Mastermind',
    url: 'https://mastermind.genomenon.com/',
    icon: '/images/links/mastermind.png'
  },
  {
    name: 'LOVD',
    url: 'https://www.lovd.nl/',
    icon: '/images/links/lovd.png'
  },
  {
    name: 'gnomAD',
    url: 'https://gnomad.broadinstitute.org/',
    icon: '/images/links/th.png'
  },
  {
    name: 'UCSC',
    url: 'https://genome.ucsc.edu/',
    icon: '/images/links/ucsc.png'
  }
]

interface Option {
  name: string
}

const DashboardSearch = (props: { samplesList: any }) => {
  // ** States
  const [value, setValue] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [open, setOpen] = useState<boolean>(false)
  const { t } = useTranslation()
  const [sampleList, setSampleList] = useState<any[]>([])

  // ** Hooks & Vars
  const router = useRouter()

  const handleRedirection = (option: any) => {
    setOpen(false)

    setValue(option.title)
    let currentSubcategory

    if (currentSubcategory !== null) {
      router.push(`/samples/SampleTabs/${option.id}/`)
    }
  }

  const fetchSampleList = useMutation({
    mutationFn: async (data: any) => await fetchSample(data),
    onSuccess: (data) => {
      console.log(data)

      if (data && data.list && Array.isArray(data.list)) {
        setSampleList(data.list)
      }
    }
  })

  return (
    <Card sx={{ position: 'relative', height: '100%' }}>
      <CardContent
        sx={{
          position: 'relative',
          backgroundSize: 'cover',
          height: '100%',
          borderRadius: '6px',
          pb: (theme) => `${theme.spacing(15)} !important`,
          background: ' url(/images/pages/Generic-DNA_hero2.png) no-repeat',
          WebkitBackgroundSize: 'cover',
          backgroundPosition: 'center',
          zIndex: 1,
          '::after': {
            content: "''",
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 2,
            background:
              'linear-gradient(to bottom, rgba(255,255,255,1), rgba(255,255,255,0.9), rgba(255,255,255,0))'
          }
        }}
      >
        <Box
          sx={{
            zIndex: 99,
            display: 'flex',
            textAlign: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            position: 'relative',
            height: '100%',
            justifyContent: 'center'
          }}
        >
          <Stack
            spacing={{ xs: 1, sm: 2 }}
            direction="row"
            useFlexGap
            flexWrap="wrap"
            px={50}
          >
            {links.map((link) => (
              <Button
                variant="outlined"
                href={link.url}
                color="inherit"
                target="_blank"
                sx={{
                  flex: isWindow()
                    ? '1 0 calc(15% - 16px)'
                    : '1 0 calc(45% - 16px)',
                  mt: 4,
                  fontSize: 16,
                  mx: 6
                }}
                fullWidth
              >
                <img
                  src={link.icon}
                  alt="delete"
                  style={{ width: 24, height: 24, marginRight: 5 }}
                ></img>

                {link.name}
              </Button>
            ))}
          </Stack>

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column'
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                mt: 10,
                justifyContent: 'center'
              }}
            >
              <Box
                sx={{
                  width: isWindow() ? '40%' : '30%',
                  height: '2rem',
                  background: ' url(/images/pages/union.svg) no-repeat',
                  backgroundPosition: 'center, center'
                }}
              ></Box>

              <Typography
                variant="h5"
                sx={{
                  textAlign: 'center',
                  color: 'primary.main',
                  fontWeight: 600,
                  position: 'relative',
                  mx: 4
                }}
              >
                {t('Sample retrieval')}
              </Typography>

              <Box
                sx={{
                  width: isWindow() ? '40%' : '30%',
                  height: '2rem',
                  background: ' url(/images/pages/union2.svg) no-repeat',
                  backgroundPosition: 'center, center'
                }}
              ></Box>
            </Box>

            <Box
              width={'100%'}
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                sx={{
                  my: 10,
                  '& + .MuiAutocomplete-popper .MuiAutocomplete-listbox': {
                    maxHeight: 400
                  }
                }}
                options={
                  sampleList.length
                    ? sampleList
                    : Array.isArray(props.samplesList?.list)
                    ? props.samplesList.list
                    : []
                }
                getOptionLabel={(option: any) => option.name}
                renderOption={(props, option: any) => (
                  <Box
                    component="li"
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                    onClick={() => handleRedirection(option)}
                  >
                    {option.name}
                  </Box>
                )}
                renderInput={(params: any) => (
                  <TextField
                    onChange={async (e) => {
                      console.log(e.target.value)
                      if (e.target.value) {
                        const query = {
                          name: e.target.value,
                          pageNum: 1,
                          pageSize: 10
                        }

                        await fetchSampleList.mutateAsync(query)
                      } else setSampleList([])
                    }}
                    {...params}
                    placeholder={t('Enter sample name')}
                  />
                )}
              />

              {/* <Button
            onClick={async () => {
              const query = {
                name: name,
                pageNum: 1,
                pageSize: 10
              }
              if (name) await fetchSampleList.mutateAsync(query)
            }}
            startIcon={<Search />}
            variant="contained"
            size="large"
          >
            {t('Search')}
          </Button> */}
            </Box>
          </Box>
        </Box>

        {/* <Typography variant="body2"></Typography> */}
      </CardContent>
    </Card>
  )
}

export default DashboardSearch

// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import { useTheme } from '@mui/material/styles'
import CardHeader from '@mui/material/CardHeader'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'

// ** Icons Imports
import DotsVertical from 'mdi-material-ui/DotsVertical'

// ** Third Party Imports
import { ApexOptions } from 'apexcharts'

// ** Custom Components Imports
import ReactApexcharts from 'src/@core/components/react-apexcharts'
import { useTranslation } from 'react-i18next'
import { salesData } from './StatisticsCard'

const MonthlyOverview = (props: any) => {
  const chartInfo = props.samplesData
  const { t } = useTranslation()

  // ** Hook
  const theme = useTheme()

  const options: ApexOptions = {
    chart: {
      parentHeightOffset: 0,
      toolbar: { show: false }
    },
    colors: salesData.map((item) => item.color),
    plotOptions: {
      bar: {
        borderRadius: 9,
        distributed: true,
        columnWidth: '40%',
        endingShape: 'rounded',
        startingShape: 'rounded'
      }
    },
    stroke: {
      width: 2,
      colors: [theme.palette.background.paper]
    },
    legend: { show: false },
    grid: {
      strokeDashArray: 7,
      padding: {
        top: -1,
        right: 0,
        left: -12,
        bottom: 5
      }
    },
    dataLabels: { enabled: false },
    states: {
      hover: {
        filter: { type: 'none' }
      },
      active: {
        filter: { type: 'none' }
      }
    },
    xaxis: {
      tickPlacement: 'on',
      labels: { show: true },
      axisTicks: { show: true },
      axisBorder: { show: true }
    },
    yaxis: {
      labels: {
        offsetX: -17,
        formatter: (value) => `${value.toFixed(0)}`
      }
    }
  }

  return (
    <Card
      sx={{
        height: '100%'
      }}
    >
      {/* <CardHeader
        title={t('Number of samples in the last 30 days')}
        titleTypographyProps={{
          sx: {
            letterSpacing: '0.15px !important',
            '::before': {
              content: '""',
              display: 'inline-block',
              width: 4,
              height: '1.6rem',
              bgcolor: 'primary.main',
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              left: '-15px'
            },
            lineHeight: '1.6rem',
            position: 'relative',
            ml: '15px'
          }
        }}

        // action={
        //   <IconButton size='small' aria-label='settings' className='card-more-options' sx={{ color: 'text.secondary' }}>
        //     <DotsVertical />
        //   </IconButton>
        // }
      /> */}

      <CardContent
        sx={{ '& .apexcharts-xcrosshairs.apexcharts-active': { opacity: 0 } }}
      >
        <Typography
          variant="h6"
          sx={{
            '::before': {
              content: '""',
              display: 'inline-block',
              width: 4,
              height: '1.6rem',
              bgcolor: 'primary.main',
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              left: '-15px'
            },
            lineHeight: '1.6rem',
            position: 'relative',
            ml: '15px'
          }}
        >
          {t('Number of samples in the last 30 days')}
        </Typography>

        <ReactApexcharts
          type="bar"
          height={220}
          options={options}
          series={[
            {
              data: [
                {
                  x: 'WES',
                  y: +chartInfo?.wes_month
                },
                {
                  x: 'WGS',
                  y: +chartInfo?.wgs_month
                },
                {
                  x: 'RNA',
                  y: +chartInfo?.rna_month
                },
                {
                  x: 'MicroArray',
                  y: +chartInfo?.microarray_month
                },
                {
                  x: 'Panel',
                  y: +chartInfo?.gene_panel_month
                }
              ]
            }
          ]}
        />

        {/* <Box sx={{ mb: 7, display: 'flex', alignItems: 'center' }}>
          <Typography variant="h5" sx={{ mr: 4 }}>
            45%
          </Typography>

          <Typography variant="body2">
            Your sales performance is 45% 😎 better compared to last month
          </Typography>
        </Box> */}

        {/* <Button fullWidth variant="contained">
          Details
        </Button> */}
      </CardContent>
    </Card>
  )
}

export default MonthlyOverview

// ** MUI Imports
import Grid from '@mui/material/Grid'

// ** Icons Imports
import Poll from 'mdi-material-ui/Poll'
import CurrencyUsd from 'mdi-material-ui/CurrencyUsd'
import HelpCircleOutline from 'mdi-material-ui/HelpCircleOutline'
import BriefcaseVariantOutline from 'mdi-material-ui/BriefcaseVariantOutline'

// ** Custom Components Imports
import CardStatisticsVerticalComponent from 'src/@core/components/card-statistics/card-stats-vertical'

// ** Styled Component Import
import ApexChartWrapper from 'src/@core/styles/libs/react-apexcharts'

// ** Demo Components Imports
import Table from 'src/views/dashboard/Table'
import Trophy from 'src/views/dashboard/Trophy'
import TotalEarning from 'src/views/dashboard/TotalEarning'
import StatisticsCard from 'src/views/dashboard/StatisticsCard'
import MonthlyOverview from '@/views/dashboard/MonthlyOverview'
import DepositWithdraw from 'src/views/dashboard/DepositWithdraw'
import SalesByCountries from 'src/views/dashboard/SalesByCountries'
import { useQuery } from 'react-query'
import {
  fetchSample,
  fetchSamplesCharts,
  fetchUsersCharts
} from '@/@core/services/app.service'
import DashboardSearch from '@/views/dashboard/Search'
import { isWindow } from '@/@core/utils/utils'

export default function Dashboard() {
  // const { data: usersData, refetch: userRefetch } = useQuery(
  //   ['usersData'],
  //   async () => {
  //     return await fetchUsersCharts()
  //   },
  //   {
  //     keepPreviousData: true
  //   }
  // )
  const { data: samplesData } = useQuery(
    ['samplesData'],
    async () => {
      return await fetchSamplesCharts()
    },
    {
      keepPreviousData: true
    }
  )

  const pagination = {
    pageNum: 0,
    pageSize: 10
  }

  const { data: samplesList } = useQuery(
    ['samplesList'],
    async () => {
      const query = {
        ...pagination,
        pageNum: pagination.pageNum + 1
      }

      return await fetchSample(query)
    },
    {
      keepPreviousData: true
    }
  )

  console.log(samplesData, 11)

  if (typeof window === 'undefined') {
    console.log('window is undefined')

    return <></>
  } else {
    return (
      <ApexChartWrapper
        sx={{
          height: '100%',
          padding: '0 25px',
          backgroundColor: 'rgb(242,244,245)',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Grid container spacing={4}>
          <Grid maxHeight={300} item xs={12} md={4} lg={2}>
            <Trophy samplesData={samplesData} samplesList={samplesList} />
          </Grid>

          <Grid item maxHeight={isWindow() ? 300 : 350} xs={12} md={8} lg={6}>
            <StatisticsCard samplesData={samplesData} />
          </Grid>

          <Grid item maxHeight={300} xs={12} md={12} lg={4}>
            <MonthlyOverview samplesData={samplesData} />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={4}>
            <TotalEarning />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <CardStatisticsVerticalComponent
                  stats="$25.6k"
                  icon={<Poll />}
                  color="success"
                  trendNumber="+42%"
                  title="Total Profit"
                  subtitle="Weekly Profit"
                />
              </Grid>

              <Grid item xs={6}>
                <CardStatisticsVerticalComponent
                  stats="$78"
                  title="Refunds"
                  trend="negative"
                  color="secondary"
                  trendNumber="-15%"
                  subtitle="Past Month"
                  icon={<CurrencyUsd />}
                />
              </Grid>

              <Grid item xs={6}>
                <CardStatisticsVerticalComponent
                  stats="862"
                  trend="negative"
                  trendNumber="-18%"
                  title="New Project"
                  subtitle="Yearly Project"
                  icon={<BriefcaseVariantOutline />}
                />
              </Grid>

              <Grid item xs={6}>
                <CardStatisticsVerticalComponent
                  stats="15"
                  color="warning"
                  trend="negative"
                  trendNumber="-18%"
                  subtitle="Last Week"
                  title="Sales Queries"
                  icon={<HelpCircleOutline />}
                />
              </Grid>
            </Grid>
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <SalesByCountries />
          </Grid>

          <Grid item xs={12} md={12} lg={8}>
            <DepositWithdraw />
          </Grid>

          <Grid item xs={12}>
            <Table />
          </Grid> */}
        </Grid>

        <Grid container mt={4} flex={1}>
          <Grid item xs={12} md={12} lg={12}>
            <DashboardSearch samplesList={samplesList} />
          </Grid>
        </Grid>
      </ApexChartWrapper>
    )
  }
}
